@tailwind base;
@tailwind components;
@tailwind utilities;

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body,
*,
html {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.bg-quiz-page {
  background-image: url("./assets/bg-quiz-page.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-quiz-result {
  background-image: url("./assets/bg-quiz-result.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-survey-page {
  background-image: url("./assets/bg-survey-page.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.landing-gradient {
  background: hsl(216, 100%, 50%);
  background: -webkit-linear-gradient(
    to left,
    hsl(216, 100%, 50%) 0%,
    hsl(133, 94%, 60%) 100%
  );
  background: -moz-linear-gradient(
    to left,
    hsl(216, 100%, 50%) 0%,
    hsl(133, 94%, 60%) 100%
  );
  background: linear-gradient(
    to left,
    hsl(216, 100%, 50%) 0%,
    hsl(133, 94%, 60%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.landing-button-gradient {
  background: hsl(133, 94%, 60%);
  background: linear-gradient(
    237deg,
    hsl(133, 94%, 60%) 0%,
    hsl(216, 100%, 50%) 100%
  );
}
.landing-banner-gradient {
  background: hsl(133, 94%, 60%);
  background: linear-gradient(
    259deg,
    hsl(133, 94%, 60%) 0%,
    hsl(216, 100%, 50%) 100%
  );
}
.bg-button-gradient {
  background: hsl(216, 100%, 50%);
  background: linear-gradient(
    218deg,
    hsl(216, 100%, 50%) 0%,
    hsl(133, 94%, 60%) 100%
  );
}
.bg-gradient {
  background: hsl(216, 100%, 50%);
  background: linear-gradient(
    180deg,
    hsl(216, 100%, 50%) 0%,
    hsl(133, 94%, 60%) 100%
  );
}
.bg-gradient-green {
  background: hsl(133, 94%, 60%);
  background: linear-gradient(
    218deg,
    hsl(133, 94%, 60%) 0%,
    hsl(112, 100%, 28%) 100%
  );
}
.bg-gradient-yellow {
  background: hsl(36, 100%, 50%);
  background: linear-gradient(
    184deg,
    hsl(36, 100%, 50%) 0%,
    hsl(4, 72%, 51%) 100%
  );
}
.bg-gradient-blue {
  background: hsl(216, 100%, 50%);
  background: linear-gradient(
    180deg,
    hsl(216, 100%, 50%) 0%,
    hsl(216, 100%, 23%) 100%
  );
}
.logo-gradient {
  background: hsl(216, 100%, 50%);
  background: -webkit-linear-gradient(
    to bottom,
    hsl(216, 100%, 50%) 0%,
    hsl(133, 94%, 60%) 100%
  );
  background: -moz-linear-gradient(
    to bottom,
    hsl(216, 100%, 50%) 0%,
    hsl(133, 94%, 60%) 100%
  );
  background: linear-gradient(
    to bottom,
    hsl(216, 100%, 50%) 0%,
    hsl(133, 94%, 60%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.w-48per {
  width: 48%;
}
.w-49per {
  width: 49%;
}
.h-48per {
  height: 48%;
}
.w-30per {
  width: 30%;
}
.w-40per {
  width: 40%;
}
.w-46per {
  width: 46%;
}
.w-48per {
  width: 48%;
}
.w-900 {
  width: 900px;
}
.w-5000 {
  width: 5000px;
}
.modal-styles {
  padding: 0;
  height: 80%;
  width: 70%;
  top: 50%;
  left: 50%;
  right: auto;
  left: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
progress[value]::-webkit-progress-bar {
  background-color: hsl(0, 0%, 82%);
}
progress[value]::-webkit-progress-value {
  background-color: hsl(216, 100%, 50%);
}
.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #0066ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
.landing-slider .slick-track {
  display: flex !important;
  justify-content: space-between !important;
}
.landing-slider .slick-list {
  padding: 20px 60px !important;
}
.landing-slider .slick-slide {
  margin: 0 10px !important;
}
.slider-min-padding .slick-list {
  padding: 20px 15px !important;
}
.slider-min-padding .slick-slide {
  padding-right: 10px !important;
}
.legend li {
  position: relative;
}
.legend li::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -30px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.legend li:nth-child(1):before {
  background: hsl(239deg 27% 48%);
}
.legend li:nth-child(2):before {
  background: hsl(133deg 61% 68%);
}
.legend li:nth-child(3):before {
  background: hsl(216deg 96% 56%);
}
.scroller::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
.scroller::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}
.scroller::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}
.scroller::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
.page-content h1 {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-top: 2rem;
}
.page-content h2 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 2rem;
}
.page-content h3 {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 2rem;
}
.carousel-item:nth-child(n + 5) {
  opacity: 0.3;
}
.landing-how-it-works ol {
  list-style: auto;
}
.landing-how-it-works ol li b {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.landing-how-it-works ol li {
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: 300;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.h-video {
  height: 528px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  .bg-quiz-result {
    background-size: contain;
  }
}

@media all and (min-width: 150px) and (max-width: 767px) {
  .bg-survey-page {
    background-image: none;
  }
  .bg-quiz-result {
    background-image: none;
  }
  .bg-quiz-page {
    background-image: url("./assets/mobile-quiz-result.svg");
  }
  .bg-audio-mobile {
    background-image: url("./assets/bg-mobile-audio.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .logo-gradient {
    background: hsl(216, 100%, 50%);
    background: -webkit-linear-gradient(
      to bottom,
      hsl(216, 100%, 50%) 50%,
      hsl(133, 94%, 60%) 100%
    );
    background: -moz-linear-gradient(
      to bottom,
      hsl(216, 100%, 50%) 50%,
      hsl(133, 94%, 60%) 100%
    );
    background: linear-gradient(
      to bottom,
      hsl(216, 100%, 50%) 50%,
      hsl(133, 94%, 60%) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .border-gradient {
    background: hsla(216, 100%, 50%, 1);
    background: linear-gradient(
      90deg,
      hsla(216, 100%, 50%, 1) 0%,
      hsla(133, 94%, 60%, 1) 100%
    );
  }
  .landing-slider .slick-slide {
    padding: 30px 0px;
  }
}
