button.carousel-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #c4c4c4;
  z-index: 9;
  top: 25%;
}
.next-arrow {
  right: 0;
}
.prev-arrow {
  left: 0;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
button.carousel-arrow:hover {
  background-color: hsla(0, 0%, 77%, 0.5);
}
@media all and (min-width: 768px) and (max-width: 1023px) {
  button.carousel-arrow {
    width: 34px;
    height: 34px;
  }
  .next-arrow {
    right: 5px;
  }
  .prev-arrow {
    right: -5px;
  }
}