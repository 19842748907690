@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}
#spinner {
  min-width: 8vh;
  min-height: 8vh;
  border: 1vh solid #000000;
  border-right: 1vh solid #ffffff;
  border-left: 1vh solid #ffffff;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

@media screen and (min-width: 1080px) {
    #spinner {
        min-width: 4vh;
        min-height: 4vh;
        border: .5vh solid #000000;
        border-right: .5vh solid #ffffff;
        border-left: .5vh solid #ffffff;
        border-radius: 50%;
        animation: spinner 1s linear infinite;
    }
}
